import { IFunctionResultModel } from 'models/functionResultModel'
import { IDoctorDataResponseModel } from 'models/doctorDataResponseModel'
import { IConsentDataResponseModel } from 'models/consentDataResponseModel'
import { IConsentPayloadModel } from 'models/consentPayloadModel'

import { IConsentInfoModel } from 'models/consentInfoModel'

import ApiConnector from '../connector'
import { IAPIActions } from '../interfaces'

class APIActions implements IAPIActions {
  getDoctorData = async (id: string) => {
    const result: IFunctionResultModel<IDoctorDataResponseModel | null> = {
      value: null,
      error: null,
    }

    try {
      const response = await ApiConnector.connector.get(
        `/doctor?meeting_id=${id}`
      )

      if (response.status === 200) {
        result.value = response.data
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (error) {
      result.error = new Error('Network error')
    }

    return result
  }

  getConsentStatus = async (meetingId: string) => {
    const result: IFunctionResultModel<IConsentInfoModel | null> = {
      value: null,
      error: null,
    }
    
    try {
      const response = await ApiConnector.connector.get(
        `/consent_status?meeting_id=${meetingId}`
      )
      console.log('status is', response)
      if (response.status === 200) {
        result.value = {
          consent1: {
            pdf: response.data.pdf,
            statusMessage: response.data.status_message,
            statusRec: {
              patientDob: response.data.status_rec.patient_dob,
              patientFirstname: response.data.status_rec.patient_firstname,
              patientLastname: response.data.status_rec.patient_surname,
              patientSignature: response.data.status_rec.patient_signature,
              submitted: response.data.status_rec.submitted,
            },
            allowed: response.data.allowed,
          },
          consent2: {
            pdf: response.data.pdf2,
            statusMessage: response.data.status_message2,
            statusRec: {
              patientDob: response.data.status_rec2.patient_dob,
              patientFirstname: response.data.status_rec2.patient_firstname,
              patientLastname: response.data.status_rec2.patient_surname,
              patientSignature: response.data.status_rec2.patient_signature,
              submitted: response.data.status_rec2.submitted,
            },
            allowed: response.data.allowed2,
          }
        }
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (error) {
      console.log('consent status error', error)
      result.error = new Error('Network error')
    }

    return result
  }

  getConsentData = async (id: string) => {
    const result: IFunctionResultModel<IConsentDataResponseModel | null> = {
      value: null,
      error: null,
    }

    try {
      const response = await ApiConnector.connector.get(
        `/consent?consent_id=${id}`
      )
      console.log('getConsent response', response)

      if (response.status === 200) {
        result.value = response.data
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (error) {
      console.log('getConsentData error', error)
      result.error = new Error('Network error')
    }

    return result
  }

  updateConsentStatus = async (formData: IConsentPayloadModel, consentId: string) => {
    const result: IFunctionResultModel<string | null> = {
      value: null,
      error: null,
    }

    try {
      const response = await ApiConnector.connector.post(
        `/consent_update_status?consent_id=${consentId}`,
        JSON.stringify(formData)
      )

      if (response.status === 201) {
        result.value = response.data
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (error) {
      result.error = new Error('Network error')
    }

    return result
  }


  override = async (formData: IConsentPayloadModel, consentId: string) => {
    const result: IFunctionResultModel<string | null> = {
      value: null,
      error: null,
    }

    try {
      const response = await ApiConnector.connector.post(
        `/consent_update_status?consent_id=${consentId}`,
        JSON.stringify(formData)
      )

      if (response.status === 201) {
        result.value = response.data
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (error) {
      result.error = new Error('Network error')
    }

    return result
  }

  validate = async (formData: IConsentPayloadModel, consentId: string) => {
    const result: IFunctionResultModel<string | null> = {
      value: null,
      error: null,
    }

    try {
      const response = await ApiConnector.connector.post(
        `/consent_response?consent_id=${consentId}`,
        JSON.stringify(formData)
      )

      if (response.status === 201) {
        result.value = response.data.response
      } else {
        result.error = new Error(`API error, status: ${response.status}`)
      }
    } catch (error) {
      result.error = new Error('Network error')
    }

    return result
  }
}

export default new APIActions()

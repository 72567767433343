import React, { FC } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

import DoctorView from "view/screens/DoctorView";
import ConsentView from "view/screens/ConsentView";
import NotFoundPage from "view/screens/404";

const GeneralRouterComponent: FC = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/doctor" component={DoctorView} />
      <Route exact path="/consent" component={ConsentView} />
      <Route path="/404" component={NotFoundPage} />
      <Redirect to="/404" />
    </Switch>
  </BrowserRouter>
);

export default GeneralRouterComponent;


import Axios, { AxiosInstance } from 'axios'
import Config from '../config.json'
import AwsGlobal from '../aws-global.json'
import * as qs from "query-string";
class ApiConnector {
  connector: AxiosInstance

  constructor() {
    var paramsx = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    // console.log('base URL is', Config.baseUrl)
    console.log('env is', AwsGlobal.env)
    const env = AwsGlobal.env

    let regionCode = paramsx["region_code"];
    if (!regionCode){
      regionCode='us'
    }
    console.log('region code is', regionCode)
    // now compute the actual base url
    console.log(AwsGlobal.aws_json_patient[AwsGlobal.env][regionCode])
    let baseUrl=AwsGlobal.aws_json_patient[AwsGlobal.env][regionCode]['consent_gw_url']
    if (!baseUrl){
      // for some reason there's a typo - this deals with edge cases for legacy code
      baseUrl=AwsGlobal.aws_json_patient[AwsGlobal.env][regionCode]['cognito_gw_url']
    }
    console.log('base url is', baseUrl)
    this.connector = Axios.create({
      baseURL: baseUrl,
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  }
}

export default new ApiConnector()

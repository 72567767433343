import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Loader from "view/screens/Components/Loader";
// import { getParam } from "common/lib";

import { IConsentInfoModel } from "models/consentInfoModel";

import { Fade, Button } from "@material-ui/core";

import PDFDocument from "./PDFDocument";
import PatientStatus from "./PatientStatus";
import PatientData from "./PatientData";
import DoctorGreeting from "./DoctorGreeting";
import styles from "./styles.module.scss";
import ApiConnector from "../../../delivery/connector";
import * as qs from "query-string";
interface IDoctorViewComponentProps {
  isLoading: boolean;
  consentStart: string;
  consentStart2: string;
  zoomStart: string;
  doctorName: string;
  patientName: string;
  consentInfo: IConsentInfoModel;
  errorMessage: string | null;
  getDoctorData: (id: string) => void;
  checkConsentStatus: (meetingId: string) => void;
  consentId: string;
  consentId2: string;
  consentHtml: string;
  consentHtml2: string;
  patientFirstname: string;
  patientLastname: string;
  patientDob: string;
  consentLabel1: string;
  consentLabel2: string;
}

const DoctorViewComponent: FC<IDoctorViewComponentProps> = ({
  isLoading,
  consentStart,
  consentStart2,
  zoomStart,
  doctorName,
  patientName,
  consentInfo,
  errorMessage,
  checkConsentStatus,
  getDoctorData,
  consentId,
  consentId2,
  consentHtml,
  consentHtml2,
  patientFirstname,
  patientLastname,
  patientDob,
  consentLabel1,
  consentLabel2,
}) => {
  const { submitted } = consentInfo.consent1.statusRec;
  const { submitted: submitted2 } = consentInfo.consent2.statusRec;
  const history = useHistory();
  console.log("consentInfo", consentInfo);
  useEffect(() => {
    var paramsx = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const currentUrl = window.location.href;
    // const meetingId = getParam(currentUrl, "meeting_id");
    const meetingId = paramsx["meeting_id"];
    if (meetingId) {
      getDoctorData(meetingId);
      checkConsentStatus(meetingId);
    } else {
      history.push("/404");
    }
  }, []);

  return (
    <div className={styles.doctorScreen}>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <Fade in={!isLoading} timeout={500}>
          {errorMessage ? (
            <div className={styles.errorContainer}>
              <p>{errorMessage}</p>
            </div>
          ) : (
            <div className={styles.container}>
              <DoctorGreeting
                consentStart={consentStart}
                consentStart2={consentStart2}
                zoomStart={zoomStart}
                doctorName={doctorName}
                patientName={patientName}
                consentId={consentId}
                consentId2={consentId2}
                consentHtml={consentHtml}
                consentHtml2={consentHtml2}
                patientFirstname={patientFirstname}
                patientLastname={patientLastname}
                patientDob={patientDob}
                consentInfo={consentInfo}
                consentLabel1={consentLabel1}
                consentLabel2={consentLabel2}
              />
              <PatientData
                patientFirstname={patientFirstname}
                patientLastname={patientLastname}
                patientDob={patientDob}
              />
              <Button
                variant="contained"
                color="primary"
                // ref={buttonRef}
                onClick={async () => {
                  console.log("do something");
                  // pass in the first consent ID - we can resolve which one is being filled in behind the scenes
                  const response = await ApiConnector.connector.get(
                    `/override?consent_id=${consentId}`
                  );
                  console.log(response);
                }}
                fullWidth
              >
                Override/Accept Patient details
              </Button>
              {consentInfo.consent1.statusMessage !== "" && (
                <PatientStatus
                  patientStatus={consentInfo.consent1}
                  header="Treatment Consent"
                />
              )}
              {consentInfo.consent2.statusMessage !== "" && (
                <PatientStatus
                  patientStatus={consentInfo.consent2}
                  header="Trial Consent"
                />
              )}
              {consentInfo.consent1.pdf && (
                <PDFDocument
                  pdf={consentInfo.consent1.pdf}
                  submitted={submitted}
                />
              )}

              {!consentInfo.consent1.pdf && (
                <div>
                  <h1>Treatment Consent</h1>
                  <p dangerouslySetInnerHTML={{ __html: consentHtml }} />
                </div>
              )}
              {/* {submitted2 && (
                <PDFDocument pdf={consentInfo.consent2.pdf} submitted={submitted2} />
              )}

              {!submitted2 && (
                <div>
                  <h1>Trial Consent</h1>
                  <p dangerouslySetInnerHTML={{ __html: consentHtml2 }} />
                </div>
              )} */}
            </div>
          )}
        </Fade>
      )}
    </div>
  );
};

export default DoctorViewComponent;

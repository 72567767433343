import { IContentScreenService } from 'actions/layerInterfaces'
import delivery from 'delivery'

import { IFormDataModel } from 'models/formDataModel'
import { ProtoBloc } from 'actions/ProtoBloc'
import { IContentScreenState } from './stateModel'

const defaultAuthState: IContentScreenState = {
  isLoading: true,
  completed: null,
  consentUpdateStatus: '',
  patientValidation: '',
  zoomJoin: '',
  consentId: '',
  consentHtml: '',
  consentResponse: '',
  isValid: false,
  failedValidation: null,
  errorMessage: null,
  forwardUrl: '',
}

class ContentScreenBloc extends ProtoBloc<IContentScreenState> implements IContentScreenService {
  constructor() {
    super(defaultAuthState)
  }

  getConsentData = async (id: string) => {
    const { value, error } = await delivery.APIActions.getConsentData(id)

    if (error) {
      this.pushState({
        ...this.state,
        isLoading: false,
        errorMessage: 'Oops something went wrong...',
      })
      return
    }

    if (value) {
      this.pushState({
        ...this.state,
        isLoading: false,
        completed: value.completed,
        consentUpdateStatus: value.consent_update_status,
        patientValidation: value.patient_validation,
        consentHtml: value.consent_html,
        zoomJoin: value.zoom_join,
        consentId: id,
        forwardUrl: value.forward_url,
      })
    }
  }

  updateConsentStatus = async (formData: IFormDataModel, submitted?: boolean) => {
    const formBody = {
      patient_firstname: formData.firstName,
      patient_surname: formData.lastName,
      patient_dob: formData.date,
      patient_signature: formData.signature,
      patient_clicked_consent: formData.checked,
      submitted: !!submitted,
    }

    const { value, error } = await delivery.APIActions.updateConsentStatus(formBody, this.state.consentId)

    if (error) {
      console.warn('something went wrong')

      return
    }

    if (value) {
      this.pushState({
        ...this.state,
        consentResponse: value
      })
    }
  }

  validate = async (formData: IFormDataModel) => {
    const formBody = {
      patient_firstname: formData.firstName,
      patient_surname: formData.lastName,
      patient_dob: formData.date,
      patient_signature: formData.signature,
      patient_clicked_consent: formData.checked,
      submitted: true,
    }

    const { value, error } = await delivery.APIActions.validate(formBody, this.state.consentId)

    if (error) {
      console.warn('something went wrong')

      return
    }

    if (value) {
      this.pushState({
        ...this.state,
        isValid: value === 'true',
        failedValidation: value === 'false'
      })

      if (this.state.isValid) {
        this.updateConsentStatus(formData, true)
      }
    }
  }
}

export default new ContentScreenBloc()

import React, { FC } from 'react'
// import { Fade, Grid } from '@material-ui/core'
import { Grid, Fade, Tooltip, Button } from "@material-ui/core";
import { IConsentInfoModel, IConsentInfoSingleModel } from 'models/consentInfoModel'

import cn from 'classnames'
import styles from './styles.module.scss'

interface IPatientStatusProps {
  patientStatus: IConsentInfoSingleModel;
  header: string;
}

const PatientStatus: FC<IPatientStatusProps> = ({
  patientStatus, header
}) => {
  const {
    patientFirstname,
    patientLastname,
    patientDob,
    patientSignature,
    submitted,
  } = patientStatus.statusRec

  const statusClassNames = cn(styles.statusText, {
    [styles.submitted]: submitted
  })

  return (
    <Fade
      in={patientStatus.statusMessage !== 'aa'}
      timeout={500}
    >
      <div className={styles.patientStatus}>
        <p className={styles.formStatus}>{header} status:</p>
        <p
          className={statusClassNames}
          dangerouslySetInnerHTML={{ __html: patientStatus.statusMessage }}
        />
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <p className={styles.lineItem}>
              <span>First Name: </span>
              <span className={styles.value}>{patientFirstname}</span>
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <p className={styles.lineItem}>
              <span>Last Name: </span>
              <span className={styles.value}>{patientLastname}</span>
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <p className={styles.lineItem}>
              <span>Date of Birth: </span>
              <span className={styles.value}>{patientDob}</span>
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <p className={styles.lineItem}>
              <span>Signature: </span>
              <span className={styles.value}>{patientSignature ? 'Signed' : ''}</span>
            </p>
          </Grid>
        </Grid>
        {!submitted && (
          <Grid
            item
            xs={12}
            sm={6}
          >

          
          </Grid>
        )}

      </div>
    </Fade>
  )
}

export default PatientStatus

import React, { FC, ChangeEvent, useRef } from 'react'

import { Grid, Button, TextField, Checkbox, FormControlLabel } from '@material-ui/core'
import SignatureCanvas from 'react-signature-canvas'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'

import { IFormDataModel } from 'models/formDataModel'

import styles from './styles.module.scss'

interface IConsentFormProps {
  updateConsentStatus: (FormData: IFormDataModel, submitted?: boolean) => void;
  validate: (FormData: IFormDataModel) => void;
}
var buttonDebounce = false
const ConsentFormComponent: FC<IConsentFormProps> = ({ updateConsentStatus, validate }) => {
  const sigCanvas = useRef<SignatureCanvas>(null)

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      date: null,
      checked: null,
      signature: null,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      date: Yup.string().required('Required'),
      checked: Yup.boolean().required('Required'),
      signature: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      // the button could be pressed a lot - so disable for 3 seconds to allow the back-end to process
      // the data
      if (buttonDebounce == false) {
        buttonDebounce = true
        console.log('bounce', buttonDebounce, disableButton)
        setTimeout(function () { buttonDebounce = false; }, 5000);
        validate(values)
      } else {
        console.log('debouncing')
      }
    },
  })

  const handleChange = (name: string, value: string | boolean | null) => {
    formik.setFieldValue(name, value)
  }

  const handleCanvasClear = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear()
      handleChange('signature', '')
    }
  }

  const handleSignature = () => {
    if (sigCanvas.current) {
      const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')

      handleChange('signature', signature)
      updateConsentStatus(formik.values)
    }
  }

  const disableButton = !(formik.isValid && formik.dirty)

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <form
        className={styles.form}
        onSubmit={formik.handleSubmit}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={4}
          >
            <TextField
              id="first-name"
              name="first-name"
              label="First Name"
              type="text"
              required
              variant="standard"
              value={formik.values.firstName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('firstName', e?.target.value)}
              onFocus={() => updateConsentStatus(formik.values)}
              onBlur={() => updateConsentStatus(formik.values)}
              autoComplete="off"
              classes={{
                root: styles.textField
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
          >
            <TextField
              id="last-name"
              name="last-name"
              label="Last Name"
              variant="standard"
              autoComplete="off"
              type="text"
              required
              value={formik.values.lastName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('lastName', e?.target.value)}
              onFocus={() => updateConsentStatus(formik.values)}
              onBlur={() => updateConsentStatus(formik.values)}
              classes={{
                root: styles.textField
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
          >
            <>
              <DatePicker
                id="date"
                name="date"
                label="Date of Birth"
                autoComplete="off"
                disableFuture
                value={formik.values.date}
                openTo="year"
                clearable
                onChange={(date) => handleChange('date', moment(date).format('YYYY-MM-DD'))}
                onFocus={() => updateConsentStatus(formik.values)}
                onBlur={() => updateConsentStatus(formik.values)}
                format="MMMM Do YYYY"
                className={styles.textField}
              />
            </>
          </Grid>
        </Grid>
        <div />
        <div className={styles.signaturePad}>
          <p>Signature:</p>
          <SignatureCanvas
            ref={sigCanvas}
            penColor="black"
            canvasProps={{ height: 400, className: styles.sigCanvas }}
            onEnd={handleSignature}
          />
          <Button
            className={styles.clearButton}
            variant="outlined"
            color="primary"
            onClick={handleCanvasClear}
          >
            Reset
          </Button>
        </div>
        <FormControlLabel
          control={
            <Checkbox
              onFocus={() => updateConsentStatus(formik.values)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('checked', e.target.checked)}
            />
          }
          label="Confirm Your Consent"
          className={styles.checkBox}
        />
        <Button
          variant="contained"
          color="primary"
          className={styles.submitButton}
          type="submit"
          disabled={disableButton}
        >
          Submit
        </Button>
      </form>
    </MuiPickersUtilsProvider>
  )
}

export default ConsentFormComponent
